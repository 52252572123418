<template>
	<div>
		<f-card>
			<div v-if="showStatus" class="status-alert">
				{{ titles[term.reason.status] }}
			</div>

			<v-text-field
				outlined
				dense
				readonly
				:label="$t('labels.activities')"
				v-model="nameData" />

			<v-select
				v-model="term.vertical_id"
				outlined
				dense
				:items="list"
				:label="$t('labels.selectVertical')" />
		</f-card>

		<f-actions-btn-holder>
			<v-btn elevation="0" large @click="back">
				{{ $t("labels.btnCancel") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="save">
				{{ $t("labels.btnSave") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import {
	loadVerticals,
	loadTerm,
	loadMissing,
	saveMissing,
	saveTerm,
} from "./../../../services/verticals";
import { SETTINGS_ROUTES } from "./../../../router/settings";

export default {
	data: () => ({
		isMissingPage: false,
	}),
	computed: {
		showStatus() {
			return this.isMissingPage && typeof this.term.reason != "undefined";
		},
		nameData() {
			if (this.isMissingPage) {
				return this.term.data;
			} else {
				return this.term.name;
			}
		},
		term() {
			return this.$store.state.verticals.view.term;
		},
		list() {
			return this.$store.state.verticals.list.map((item) => ({
				text: item.title,
				value: item.id,
			}));
		},
		titles() {
			try {
				return {
					not_able_to_classified_automatically: this.$t(
						"pages.settings.verticalActivityRel.message.notAbleToClassify"
					),
					conflict: this.$t(
						"pages.settings.verticalActivityRel.message.verticalConflict",
						{
							old: this.term.reason.verticals.current,
							new: this.term.reason.verticals.new,
						}
					),
				};
			} catch (e) {
				return {
					not_able_to_classified_automatically: this.$t(
						"pages.settings.verticalActivityRel.message.notAbleToClassify"
					),
					conflict: this.$t(
						"pages.settings.verticalActivityRel.message.verticalConflict",
						{
							old: '-',
							new: '-',
						}
					),
				};
			}
		},
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			const resourceID = this.$route.params.id;
			await loadVerticals();

			this.isMissingPage =
				this.$route.name == SETTINGS_ROUTES.VERTICALS_REL_MISSING_EDIT;

			if (this.isMissingPage) {
				await loadMissing(resourceID);

				this.term.reason = JSON.parse(this.term.reason);
			} else {
				await loadTerm(resourceID);
			}
		},
		async save() {
			if (this.isMissingPage) {
				await saveMissing();
			} else {
				await saveTerm();
			}

			this.back();
		},
		back() {
			this.$router.push({
				name: this.isMissingPage
					? SETTINGS_ROUTES.VERTICALS_REL_INDEX
					: SETTINGS_ROUTES.VERTICALS_REL_TERMS,
			});
		},
	},
};
</script>

<style lang="scss">
.status-alert {
	background-color: #cccccc;
	border-radius: 3px;
	margin-bottom: 24px;
	padding: 12px 24px;
	font-weight: bold;
}
</style>
