<template>
	<div>
		<global-menu />

		<f-default-header
			:title="$t('pages.settings.title')"
			:subtitle="
				$t('pages.settings.verticalActivityRel.subtitle.relationActivities')
			" />

		<term-form />
	</div>
</template>

<script>
import TermForm from "./../../../components/settings/verticalActivityRel/TermForm.vue";
import GlobalMenu from "../../../components/settings/verticalActivityRel/GlobalMenu.vue";

export default {
	components: {
		TermForm,
		GlobalMenu,
	},
};
</script>
